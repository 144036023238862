<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Utilisateur</h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-5">
                <div class="card shadow rounded">
                  <!--                  <img-->
                  <!--                    :src="avatarAdmin"-->
                  <!--                    height="400"-->
                  <!--                    alt="avatar.png"-->
                  <!--                    v-show="selectUser.roles[0].slug === 'admin'"-->
                  <!--                  />-->
                  <!--                  <img-->
                  <!--                    :src="avatarSuperviseur"-->
                  <!--                    height="400"-->
                  <!--                    alt="avatar.png"-->
                  <!--                    v-show="selectUser.roles[0].slug === 'superviseur'"-->
                  <!--                  />-->
                  <!--                  <img-->
                  <!--                    :src="avatarOperateur"-->
                  <!--                    height="400"-->
                  <!--                    alt="avatar.png"-->
                  <!--                    v-show="selectUser.roles[0].slug === 'opérateur'"-->
                  <!--                  />-->
                  <!--                  <img-->
                  <!--                    :src="avatarAgMobile"-->
                  <!--                    height="400"-->
                  <!--                    alt="avatar.png"-->
                  <!--                    v-show="selectUser.roles[0].slug === 'agentmobile'"-->
                  <!--                  />-->
                  <!--                  <img-->
                  <!--                    :src="avatarPartner"-->
                  <!--                    height="400"-->
                  <!--                    alt="avatar.png"-->
                  <!--                    v-show="selectUser.roles[0].slug === 'partenaire'"-->
                  <!--                  />-->
                  <!--                  <img-->
                  <!--                    :src="avatarAgenceImmo"-->
                  <!--                    height="400"-->
                  <!--                    alt="avatar.png"-->
                  <!--                    v-show="selectUser.roles[0].slug === 'agentimmobilier'"-->
                  <!--                  />-->
                  <img
                    :src="avatarUser"
                    height="400"
                    alt="avatar.png"
                    v-show="selectUser.roles === []"
                  />
                </div>
              </div>
              <div class="col-md-7">
                <div class="card shadow rounded">
                  <div class="card-body">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ selectUser.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Prenoms</th>
                        <td class="col-sm-6">{{ selectUser.prenoms }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Email</th>
                        <td class="col-sm-6">{{ selectUser.email }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Sexe</th>
                        <td class="col-sm-6" v-if="selectUser.sexe === 'M'">
                          Homme
                        </td>
                        <td class="col-sm-6" v-else>Femme</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Numéros de téléphone</th>
                        <td class="col-sm-6">{{ selectUser.telephone_1 }}</td>
                      </tr>
                      <tr class="row" v-if="selectUser.telephone_2">
                        <th class="col-sm-6">Autre numéros de téléphone</th>
                        <td class="col-sm-6">{{ selectUser.telephone_2 }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Role</th>
                        <td class="col-sm-6" v-if="selectUser.roles === []">
                          {{ selectUser.roles[0]["slug"] }}
                        </td>
                        <td class="col-sm-6" v-else>Aucun role affecter</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Status</th>
                        <td class="col-sm-6" v-if="selectUser.status">Actif</td>
                        <td class="col-sm-6" v-else>Inactif</td>
                      </tr>
                      <tr class="row" v-if="selectUser.ville_id">
                        <th class="col-sm-6">Ville</th>
                        <td class="col-sm-6">{{ selectUser.email }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
// import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "Show",
  data() {
    return {
      avatarAdmin: process.env.BASE_URL + "media/bg/admin.png",
      avatarOperateur: process.env.BASE_URL + "media/bg/operateur.png",
      avatarSuperviseur: process.env.BASE_URL + "media/bg/superviseur.png",
      avatarAgMobile: process.env.BASE_URL + "media/bg/ag_mob.png",
      avatarAgenceImmo: process.env.BASE_URL + "media/bg/agence_immo.png",
      avatarPartner: process.env.BASE_URL + "media/bg/partner.png",
      avatarUser: process.env.BASE_URL + "media/bg/user.png"
    };
  },
  computed: {
    ...mapGetters({
      selectUser: "getUser"
    })
  },
  beforeCreate() {
    this.$store.dispatch("showUser", this.$route.params.id);
  }
};
</script>

<style scoped></style>
